import { FC } from "react";

import { Box, Flex } from "@spwn-portal/ui/chakra";

/**
 * Layoutの責務
 * - コンポーネントを受け取って、配置する
 * - 受け取るコンポーネントがどのようなコンポーネントであるかは気にしない
 *   - 一部ページで別のヘッダーを使用したいとき、既存のページに変更を加えることなく対応可能
 */
export const LayoutDefault: FC<{
  header: JSX.Element;
  footer: JSX.Element;
  children: JSX.Element;
}> = ({ header, children, footer }) => {
  return (
    <Flex minHeight={"100vh"} direction={"column"}>
      <Box as={"header"}>{header}</Box>

      <Box flex={"1 1 auto"}>{children}</Box>

      <Box as={"footer"}>{footer}</Box>
    </Flex>
  );
};
