import { UrlObject } from "url";

import { useRouter } from "next/router";
import { FC, useEffect } from "react";

import { Spinner, Stack } from "@spwn-portal/ui/chakra";

type RedirectProps = {
  redirectTo: UrlObject;
};

export const Redirect: FC<RedirectProps> = ({ redirectTo }) => {
  const { replace } = useRouter();

  useEffect(() => {
    replace(redirectTo);
  }, [replace, redirectTo]);

  return (
    <Stack direction={"column"} align={"center"} padding={"12"} spacing={"4"}>
      <Spinner />
    </Stack>
  );
};
