import { GetStaticProps, NextPage } from "next";
import React, { useEffect } from "react";

import { getStaticPropsWithTranslations } from "@spwn-portal/i18n-utils/server";

import { FAN_CLUB_TITLE } from "../constants/crew";
import { HINABITA_BRAND_SLUG } from "../constants/hinabita";
import { Footer } from "../features/Footer";
import { Header } from "../features/Header";
import { HeadSeo } from "../features/HeadSeo";
import { pagesPath } from "../features/path";
import { Redirect } from "../features/Redirect";
import { LayoutDefault } from "../ui/LayoutDefault";

/**
 * @deprecated
 * crew.spwm.jpではTOPページを持たない。
 * crewのトップの機能は、spwn.jpが持つようになったので、ここではリダイレクト処理だけを行う。
 *
 * 疑問点
 * ブランドごとにURLを切っているため、crew.spwn.jpが存在しない場合、検索結果ではどのような表示になるかわからない。
 * 逆にサブドメインにしたら、そこの問題は解決しそう。
 */
const TopPage: NextPage = () => {
  useEffect(() => {
    location.replace("https://spwn.jp");
  }, []);

  return (
    <>
      <HeadSeo
        path={`/`}
        title={{
          type: "noTemplate",
          text: FAN_CLUB_TITLE,
        }}
      />
      <LayoutDefault header={<Header />} footer={<Footer />}>
        <Redirect
          redirectTo={pagesPath._brandSlug(HINABITA_BRAND_SLUG).$url()}
        />
      </LayoutDefault>
    </>
  );
};

export const getStaticProps: GetStaticProps = getStaticPropsWithTranslations;

export default TopPage;
