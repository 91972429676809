import { FC } from "react";

import { Box, Flex, Link, Stack, Text } from "@spwn-portal/ui/chakra";

/**
 * FIXME
 * デザインがthemeに存在しない色指定が多いため、仮で指定している箇所がある。
 *  - StackのborderBottom
 *  - Textのcolor
 *  - subNavのsp時のbackgroundColor
 * リリース後themeの色定義の見直しを行いたい。
 */
export const Footer: FC = () => {
  const mainNav = [
    {
      label: "ヘルプサイト",
      href: "https://spwncrew.zendesk.com/hc/ja",
    },
  ];

  const subNav = [
    {
      label: "運営会社",
      href: "https://balus.co/",
    },
    {
      label: "利用規約",
      href: "https://spwn.jp/terms-of-service",
    },
    {
      label: "特定商取引法に基づく表記",
      href: "https://spwn.jp/law",
    },
    {
      label: "資金決済法に基づく情報提供",
      href: "https://spwn.jp/payment-services-act",
    },
    {
      label: "プライバシーポリシー",
      href: "https://spwn.jp/privacy-policy",
    },
  ];

  return (
    <Box paddingY={[0, 10]} backgroundColor={"surface.dark"}>
      <Box as={"nav"} maxWidth={"100%"} paddingX={[0, 6, 10]}>
        <Stack spacing={[0, 7]}>
          <Box>
            {/* 間にborderを入れるため、Stackのspacingではなく、paddingBottomを使用 */}
            <Stack
              as={"ul"}
              direction={["column", "row"]}
              spacing={[0, 10]}
              paddingBottom={[0, 5]}
              borderBottom={["0px", "1px solid"]}
              borderColor={"border.ondark"}
              sx={{ listStyle: "none" }}
            >
              {mainNav.map((item) => (
                <Box
                  key={item.label}
                  as={"li"}
                  color={"text.ondark"}
                  fontSize={"sm"}
                  lineHeight={["1.5em", "2em"]}
                  backgroundColor={["#141718", "inherit"]}
                >
                  <Link
                    href={item.href}
                    display={"block"}
                    padding={[5, 0]}
                    cursor={"pointer"}
                    isExternal
                    rel={"noopener noreferrer"}
                  >
                    {item.label}
                  </Link>
                </Box>
              ))}
            </Stack>

            {/* chakraのpropsにlistStyleが無かったので、sxでリストスタイルを削除 */}
            {/* chakraのデフォルトのstyleでは実現出来なかったので、sxでスタイルを追加 */}
            <Flex
              as={"ul"}
              direction={["column", "row"]}
              flexWrap={"wrap"}
              paddingTop={[0, 5]}
              sx={{
                listStyle: "none",
                "li:not(:last-child)": {
                  paddingRight: [0, 10],
                },
              }}
            >
              {subNav.map((item) => (
                <Box
                  key={item.label}
                  as={"li"}
                  color={["text.ondark", "whiteAlpha.600"]}
                  fontSize={"sm"}
                  lineHeight={["1.5em", "2em"]}
                  backgroundColor={["#141718", "inherit"]}
                >
                  <Link
                    href={item.href}
                    display={"block"}
                    padding={[5, 0]}
                    cursor={"pointer"}
                    isExternal
                    rel={"noopener noreferrer"}
                  >
                    {item.label}
                  </Link>
                </Box>
              ))}
            </Flex>
          </Box>

          <Text
            paddingTop={[4, 0]}
            paddingBottom={["100px", 0]}
            color={"whiteAlpha.600"}
            fontSize={"xs"}
            textAlign={["center", "left"]}
          >
            © 2019 Balus CO., LTD. All rights reserved.
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};
